<template>
  <div class="col-12" v-cloak @click="menu = false">
    <!-- overlay div -->
    <div class="team-overlay"></div>
    <!-- Alert to show user why his Reports tab is disable -->
    <div>
      <v-alert class="ma-0" border="left" colored-border color="#304FFE">
        <v-icon color="#304FFE" size="40" class="mx-3"
          >mdi-account-question-outline</v-icon
        >
        <span class="mt-8"
          >Please invite your Team Members, you don't have any members yet.
</span
        >
        <div
          class="float-right white--text promt-btn align-center pointer"
          @click="routeToTeamMembers"
        >
          <div class="mt">Invite new members</div>
        </div>
      </v-alert>
    </div>
    <!-- Dummy task page body -->
    <div>
      <!-- Header -->
      <div class="d-flex mt-5 flex-wrap align-center">
        <div class="bold ml-1 mt-4">
          <div class="dummy-export">Download As</div>
        </div>
        <v-spacer></v-spacer>
        <div class="ml-5 bold">
          <label class="mr-16 ml-n4">Date Range</label>
          <div class="dummy-input">
            <span class="mr-16 ml-n1">Yesterday</span>
            <v-icon class="float-right mr-2">mdi-calendar-range</v-icon>
          </div>
        </div>
        <!--        <button class="float-right ml-3 create-task-button" id="add_task">-->
        <!--          <v-icon color="grey">mdi-plus</v-icon>-->
        <!--          Add / Assign Task-->
        <!--        </button>-->
      </div>
      <!-- Tasks table -->
      <div class="mt-5">
        <v-data-table
          :headers="fields"
          :items="items"
          :items-per-page="5"
          class="elevation-1"
        ></v-data-table>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
export default {
  name: "firstdashboard",
  data() {
    return {
      // Dummy Data
      items: [
        {
          assignedToName: "Jessica",
          assignedByName: "Walter",
          projectName: "Apps pro ",
          title: "Testing2",
          assignedDate: "13 days ago",
          dueDate: "Jan 2, 2021",
          lastWorked: "3 days ago",
          spentTime: "56 mins",
          TaskStatus: "Completed",
        },
        {
          assignedToName: "William",
          assignedByName: "Johnn",
          projectName: "Status que",
          title: "Reviews",
          assignedDate: "15 days ago",
          dueDate: "Jan 5, 2021",
          lastWorked: "9 days ago",
          spentTime: "346 mins",
          TaskStatus: "Pending",
        },
        {
          assignedToName: "Barriey",
          assignedByName: "Packs",
          projectName: "Game pro ",
          title: "Testing3",
          assignedDate: "2 month ago",
          dueDate: "Feb 4, 2021",
          lastWorked: "2 days ago",
          spentTime: "120 mins",
          TaskStatus: "Completed",
        },
        {
          assignedToName: "Marcel",
          assignedByName: "Swifty",
          projectName: "Social App",
          title: "Planning",
          assignedDate: "21 days ago",
          dueDate: "Feb 13, 2021",
          lastWorked: "a day ago",
          spentTime: "380 mins",
          TaskStatus: "Pending",
        },
        {
          assignedToName: "Monica",
          assignedByName: "Binng",
          projectName: "Clean App",
          title: "Planning",
          assignedDate: "21 days ago",
          dueDate: "Feb 14, 2021",
          lastWorked: "7 days ago",
          spentTime: "360 mins",
          TaskStatus: "Pending",
        },
        {
          assignedToName: "Joseph",
          assignedByName: "Tribbi",
          projectName: "Secure app",
          title: "Reviews",
          assignedDate: "31 days ago",
          dueDate: "Feb 28, 2021",
          lastWorked: "7 days ago",
          spentTime: "660 mins",
          TaskStatus: "Pending",
        },
        {
          assignedToName: "Jannice",
          assignedByName: "Brains",
          projectName: "Sleak App",
          title: "Reviews",
          assignedDate: "31 days ago",
          dueDate: "Feb 28, 2021",
          lastWorked: "7 days ago",
          spentTime: "660 mins",
          TaskStatus: "Pending",
        },
        {
          assignedToName: "Johana",
          assignedByName: "Hoppe",
          projectName: "Music App",
          title: "Testing",
          assignedDate: "42 days ago",
          dueDate: "Mar 28, 2021",
          lastWorked: "2 days ago",
          spentTime: "610 mins",
          TaskStatus: "Completed",
        },
        {
          assignedToName: "Swissy",
          assignedByName: "Brethy",
          projectName: "Music App",
          title: "Testing2",
          assignedDate: "62 days ago",
          dueDate: "Mar 18, 2021",
          lastWorked: "9 days ago",
          spentTime: "540 mins",
          TaskStatus: "Completed",
        },
        {
          assignedToName: "Aliana",
          assignedByName: "Croozty",
          projectName: "Project 21",
          title: "Testing2",
          assignedDate: "62 days ago",
          dueDate: "Mar 18, 2021",
          lastWorked: "9 days ago",
          spentTime: "540 mins",
          TaskStatus: "Completed",
        },
      ],
      fields: [
        {
          value: "assignedByName",
          text: "Team Member",
          sortable: true,
        },
        {
          value: "projectName",
          text: "Project Name",
          sortable: true,
        },
        {
          value: "title",
          text: "Title",
          sortable: true,
        },
        {
          value: "assignedDate",
          text: "Assigned Date",
          sortable: true,
        },
        {
          value: "dueDate",
          text: "Due Date",
          sortable: true,
        },
        {
          value: "lastWorked",
          text: "Last worked",
          sortable: true,
        },
        {
          value: "spentTime",
          text: "Spent Time",
          sortable: true,
        },
        {
          value: "TaskStatus",
          text: "Task Status",
        },
      ],
    };
  },
  computed: {
    ...mapState("custom", ["active_team_members_list"]),
  },
  methods: {
    //   Navigate to team members page
    routeToTeamMembers() {
      this.$router.push({
        name: "TeamMembers",
        query: {
          new: "yes",
        },
      });
    },
    routeToDownloadApp() {
      this.$router.push({
        name: "DownloadApp",
      });
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.create-task-button {
  margin-top: 26px;
  width: max-content !important;
  height: 36px !important;
  border-radius: 3px !important;
  background-color: #2758f6 !important;
  color: white !important;
  font-weight: 600;
  text-align: right;
}
.mt {
  margin-top: 5px !important;
}
.assign-task-btn {
  background-color: #304ffe;
  width: 200px;
  height: 35px;
  border-radius: 5px;
  font-size: 18px;
  color: white;
}
.promt-btn {
  width: 175px !important;
  border-radius: 5px;
  height: 35px;
  text-align: center;
  vertical-align: center;
  background-color: #304ffe;
  z-index: 1 !important;
  animation-name: bounce-2 !important;
  animation-timing-function: ease !important;
  animation-duration: 2s !important;
  animation-iteration-count: infinite !important;
}
@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.due-date-badge {
  background-color: rgb(194, 10, 10);
  color: white;
  border-radius: 4px;
  font-size: 11px;
  padding: 1px;
  margin-left: 80px !important;
}
.status-badge {
  background-color: rgb(11, 131, 11);
  color: white;
  border-radius: 4px;
  font-size: 12px;
  padding: 1px;
  margin-left: 80px !important;
}
.team-overlay {
  position: fixed;
  z-index: 0.9;
  background: #000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.8;
}
.dummy-input {
  width: 180px;
  background-color: white;
  border: 1px solid rgb(202, 217, 223);
  margin-top: 5px;
  padding: 3px 0px 3px 5px;
  border-radius: 2px;
  font-weight: 300 !important;
}
.dummy-export {
  width: 180px;
  background-color: white;
  border: 1px solid rgb(202, 217, 223);
  margin-top: 5px;
  padding: 3px 0px 3px 5px;
  border-radius: 2px;
  font-weight: 300 !important;
}
.bold {
  font-weight: 600;
}
.dummy-img-size {
  height: 40px;
  width: 40px;
}
</style>
